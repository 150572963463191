import { BASE_URL } from '@constants'

const endpoint = path => `${BASE_URL}/${path}`

export const LOGIN = endpoint('user/login')
export const FORGOT_PASSWORD = endpoint('user/forgot-password')
export const RESET_PASSWORD = endpoint('user/reset-password')
export const FETCH_USER = endpoint('user')
export const FETCH_MENU = endpoint('menu')
export const CATEGORIES = endpoint('categories')

import React, { lazy, Suspense, useCallback, useEffect } from 'react'
import {
	Routes,
	Route,
	Outlet,
	Navigate,
	useNavigate,
	useParams
} from 'react-router-dom'

import {
	SLUGS_LEVEL_1,
	SLUGS_LEVEL_2,
	ADMIN_PROTECTED_ROUTES
} from '@constants'

import { useInfo } from '@hooks/info'
import { useUser } from '@hooks/user'

import { PreloaderInside } from '@components/preloaders'

import Landing from './screens/Landing'
import NotFound from '@not-found/NotFound'

const Profile = lazy(() => import('./screens/Profile'))
const ProfileEdit = lazy(() => import('./screens/ProfileEdit'))
const ConfigEdit = lazy(() => import('./screens/ConfigEdit'))
const Category = lazy(() => import('./screens/Category'))
const List = lazy(() => import('./screens/List'))
const ListMachineryTasks = lazy(() => import('./screens/ListMachineryTasks'))
const ListCampaigns = lazy(() => import('./screens/ListCampaigns'))
const TabsList = lazy(() => import('./screens/TabsList'))
const Single = lazy(() => import('./screens/Single'))
const SingleEdit = lazy(() => import('./screens/SingleEdit'))
const SingleEditMachineryTasks = lazy(() =>
	import('./screens/SingleEditMachineryTasks')
)
const SingleCreate = lazy(() => import('./screens/SingleCreate'))
const SingleCreateMachineryTasks = lazy(() =>
	import('./screens/SingleCreateMachineryTasks')
)
const SingleAddList = lazy(() => import('./screens/SingleAddList'))
const SingleAddProduct = lazy(() => import('./screens/SingleAddProduct'))
const PlotsImport = lazy(() => import('./screens/PlotsImport'))

const PlotsCreateMap = lazy(() => import('./screens/PlotsCreateMap'))
const PlotsCombineMap = lazy(() => import('./screens/PlotsCombineMap'))
const PlotsSingle = lazy(() => import('./screens/PlotsSingle'))
const MapScreen = lazy(() => import('./screens/MapScreen'))

const Activities = lazy(() => import('./screens/Activities'))
const ActivitiesSingle = lazy(() => import('./screens/ActivitiesSingle'))
// const Products = lazy(() => import('./screens/Products'))

const Notebooks = lazy(() => import('./screens/Notebooks'))
const SingleCreateNotebooks = lazy(() =>
	import('./screens/SingleCreateNotebooks')
)

const Prices = lazy(() => import('../costs/Prices'))
const FixedPrices = lazy(() => import('../costs/FixedPrices'))
const FixedPricesSingle = lazy(() => import('../costs/FixedPricesSingle'))
const ResultsReport = lazy(() => import('../costs/ResultsReport'))

const Users = lazy(() => import('../users/Users'))
const UserCreate = lazy(() => import('../users/UserCreate'))
const UserEdit = lazy(() => import('../users/UserEdit'))

function RouteMatch404({ children }) {
	const { categoryId, pageId } = useParams()

	if (!!categoryId && !SLUGS_LEVEL_1.includes(categoryId)) {
		return <Navigate to="/not-found" replace={true} />
	}

	if (!!pageId && !SLUGS_LEVEL_2.includes(pageId)) {
		return <Navigate to="/not-found" replace={true} />
	}

	return children
}

function RouteMatchNotAdmin404({ children }) {
	const { data: user } = useUser()
	const { categoryId, pageId, ...restParams } = useParams()

	const isNotAdmin = user?.role?.group !== 'admin'

	const check = useCallback(
		key => !!key && ADMIN_PROTECTED_ROUTES.includes(key) && isNotAdmin,
		[user]
	)

	/* if (
		!!pageId &&
		pageId === 'holdings' &&
		isNotAdmin &&
		!!holdings &&
		holdings?.children?.length
	) {
		const holdingId = holdings?.children[0].id
		if (singleId) {
			if (holdingId !== singleId) {
				return <Navigate to="/not-found" replace={true} />
			}
		} else {
			return <Navigate to="/not-found" replace={true} />
		}
	} */

	if (
		check(categoryId) ||
		check(pageId) ||
		check('*' in restParams ? restParams['*'] : '')
	) {
		return <Navigate to="/not-found" replace={true} />
	}

	return children
}

function RouteFields({ plotsComponent, groupsComponent }) {
	const { pageId } = useParams()
	const keys = ['plots', 'groups']

	if (!keys.includes(pageId)) {
		return <Navigate to="/not-found" replace={true} />
	}

	return pageId === 'plots' ? plotsComponent : groupsComponent
}

function RoutesCategoriesDefault({ holdings }) {
	return (
		<Routes>
			<Route
				index
				element={
					<RouteMatch404>
						<Suspense fallback={<PreloaderInside />}>
							<Category />
						</Suspense>
					</RouteMatch404>
				}
			/>
			{/* Dynamic */}
			<Route path=":pageId" element={<Outlet />}>
				<Route
					index
					element={
						<RouteMatch404>
							<Suspense fallback={<PreloaderInside />}>
								<List holdings={holdings} />
							</Suspense>
						</RouteMatch404>
					}
				/>
				<Route
					path="create"
					element={
						<Suspense fallback={<PreloaderInside />}>
							<SingleCreate />
						</Suspense>
					}
				/>
				<Route path=":singleId" element={<Outlet />}>
					<Route
						path=""
						element={
							<Suspense fallback={<PreloaderInside />}>
								<Single />
							</Suspense>
						}
					/>
					<Route
						path="edit"
						element={
							<Suspense fallback={<PreloaderInside />}>
								<SingleEdit />
							</Suspense>
						}
					/>
					<Route path="*" element={<NotFound />} />
				</Route>
				<Route path="*" element={<NotFound />} />
			</Route>

			{/* Products */}
			<Route path="products">
				<Route path="" element={<Navigate to="phytosanitaries" replace />} />
				<Route path=":pageId" element={<Outlet />}>
					<Route
						index
						element={
							<Suspense fallback={<PreloaderInside />}>
								<TabsList />
							</Suspense>
						}
					/>
					<Route path="add" element={<Outlet />}>
						<Route
							index
							element={
								<Suspense fallback={<PreloaderInside />}>
									<SingleAddList />
								</Suspense>
							}
						/>
						<Route
							path=":productId"
							element={
								<Suspense fallback={<PreloaderInside />}>
									<SingleAddProduct />
								</Suspense>
							}
						/>
					</Route>
					<Route
						path="create"
						element={
							<Suspense fallback={<PreloaderInside />}>
								<SingleCreate />
							</Suspense>
						}
					/>
					<Route path=":singleId" element={<Outlet />}>
						<Route
							path=""
							element={
								<Suspense fallback={<PreloaderInside />}>
									<Single />
								</Suspense>
							}
						/>
						<Route
							path="edit"
							element={
								<Suspense fallback={<PreloaderInside />}>
									<SingleEdit />
								</Suspense>
							}
						/>
					</Route>
					<Route path=":singleId" element={<Outlet />}>
						<Route
							index
							element={
								<Suspense fallback={<PreloaderInside />}>
									<Single />
								</Suspense>
							}
						/>
					</Route>
				</Route>
			</Route>

			{/* Fields */}
			<Route path="fields">
				<Route path="" element={<Navigate to="plots" replace />} />
				<Route path=":pageId" element={<Outlet />}>
					<Route
						index
						element={
							<Suspense fallback={<PreloaderInside />}>
								<TabsList />
							</Suspense>
						}
					/>
					<Route
						path="create"
						element={
							<Suspense fallback={<PreloaderInside />}>
								<RouteFields
									plotsComponent={<PlotsCreateMap />}
									groupsComponent={<SingleCreate />}
								/>
							</Suspense>
						}
					/>
					<Route path=":singleId" element={<Outlet />}>
						<Route
							path=""
							element={
								<Suspense fallback={<PreloaderInside />}>
									<RouteFields
										plotsComponent={<PlotsSingle />}
										groupsComponent={<Single />}
									/>
								</Suspense>
							}
						/>
						<Route
							path="edit"
							element={
								<Suspense fallback={<PreloaderInside />}>
									<SingleEdit />
								</Suspense>
							}
						/>
					</Route>
					<Route
						path="import"
						element={
							<Suspense fallback={<PreloaderInside />}>
								<PlotsImport />
							</Suspense>
						}
					/>
				</Route>
			</Route>

			{/* Machinery tasks */}
			<Route path="machinery_tasks">
				<Route path=":machineId" element={<Outlet />}>
					<Route
						index
						element={
							<Suspense fallback={<PreloaderInside />}>
								<ListMachineryTasks />
							</Suspense>
						}
					/>
					<Route
						path="create"
						element={
							<Suspense fallback={<PreloaderInside />}>
								<SingleCreateMachineryTasks />
							</Suspense>
						}
					/>
					<Route
						path=":singleId/edit"
						element={
							<Suspense fallback={<PreloaderInside />}>
								<SingleEditMachineryTasks />
							</Suspense>
						}
					/>
				</Route>
				<Route
					path="maintenance"
					element={<Navigate from="maintenance" to="../machinery" />}
				/>
			</Route>

			{/* Campaigns */}
			<Route path="campaigns" element={<Outlet />}>
				<Route
					index
					element={
						<Suspense fallback={<PreloaderInside />}>
							<ListCampaigns />
						</Suspense>
					}
				/>
				<Route path=":pageId" element={<Outlet />}>
					<Route
						path="create"
						element={
							<Suspense fallback={<PreloaderInside />}>
								<SingleCreate />
							</Suspense>
						}
					/>
				</Route>
			</Route>

			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

function DashboardRouterInner({ holdings }) {
	return (
		<Routes>
			<Route index element={<Landing />} />
			<Route path="not-found" element={<NotFound />} />

			{/* Plot Create */}
			<Route path="plot-create" element={<Outlet />}>
				<Route
					index
					element={
						<Suspense fallback={<PreloaderInside />}>
							<PlotsCreateMap />
						</Suspense>
					}
				/>
				<Route path="*" element={<NotFound />} />
			</Route>

			{/* Map */}
			<Route path="map" element={<Outlet />}>
				<Route
					index
					element={
						<Suspense fallback={<PreloaderInside />}>
							<MapScreen />
						</Suspense>
					}
				/>
				<Route path="*" element={<NotFound />} />
			</Route>

			{/* Profile */}
			<Route
				path="profile/*"
				element={
					<Routes>
						<Route
							index
							element={
								<Suspense fallback={<PreloaderInside />}>
									<Profile />
								</Suspense>
							}
						/>
						<Route
							path="edit"
							element={
								<Suspense fallback={<PreloaderInside />}>
									<ProfileEdit />
								</Suspense>
							}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				}
			></Route>

			{/* Usuarios */}
			<Route path="users" element={<Outlet />}>
				<Route
					index
					element={
						<RouteMatchNotAdmin404>
							<RouteMatch404>
								<Suspense fallback={<PreloaderInside />}>
									<Users />
								</Suspense>
							</RouteMatch404>
						</RouteMatchNotAdmin404>
					}
				/>
				<Route
					path="create"
					element={
						<RouteMatchNotAdmin404>
							<Suspense fallback={<PreloaderInside />}>
								<UserCreate />
							</Suspense>
						</RouteMatchNotAdmin404>
					}
				/>
				<Route
					path=":singleId"
					element={
						<RouteMatchNotAdmin404>
							<Suspense fallback={<PreloaderInside />}>
								<UserEdit />
							</Suspense>
						</RouteMatchNotAdmin404>
					}
				/>
				<Route path="*" element={<NotFound />} />
			</Route>

			{/* Config */}
			<Route path="config" element={<Outlet />}>
				<Route
					index
					element={
						<Suspense fallback={<PreloaderInside />}>
							<ConfigEdit />
						</Suspense>
					}
				/>
				<Route path="*" element={<NotFound />} />
			</Route>

			{/* Costs */}
			<Route
				path="costs/*"
				element={
					<Routes>
						<Route
							path="prices"
							element={
								<RouteMatchNotAdmin404>
									<Suspense fallback={<PreloaderInside />}>
										<Prices />
									</Suspense>
								</RouteMatchNotAdmin404>
							}
						/>
						<Route path="fixed-prices" element={<Outlet />}>
							<Route
								index
								element={
									<RouteMatchNotAdmin404>
										<Suspense fallback={<PreloaderInside />}>
											<FixedPrices />
										</Suspense>
									</RouteMatchNotAdmin404>
								}
							/>
							<Route
								path="create"
								element={
									<RouteMatchNotAdmin404>
										<Suspense fallback={<PreloaderInside />}>
											<FixedPricesSingle />
										</Suspense>
									</RouteMatchNotAdmin404>
								}
							/>
							<Route
								path=":singleId"
								element={
									<RouteMatchNotAdmin404>
										<Suspense fallback={<PreloaderInside />}>
											<FixedPricesSingle />
										</Suspense>
									</RouteMatchNotAdmin404>
								}
							/>
						</Route>
						<Route
							path="results-report"
							element={
								<RouteMatchNotAdmin404>
									<Suspense fallback={<PreloaderInside />}>
										<ResultsReport />
									</Suspense>
								</RouteMatchNotAdmin404>
							}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				}
			></Route>

			{/* Activities */}
			<Route
				path="activities/*"
				element={
					<Routes>
						<Route
							index
							element={
								<Suspense fallback={<PreloaderInside />}>
									<Activities />
								</Suspense>
							}
						/>
						<Route
							path="create"
							element={
								<Suspense fallback={<PreloaderInside />}>
									<ActivitiesSingle />
								</Suspense>
							}
						/>
						<Route
							path=":id"
							element={
								<Suspense fallback={<PreloaderInside />}>
									<ActivitiesSingle />
								</Suspense>
							}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				}
			></Route>

			{/* Notebooks */}
			<Route
				path="notebooks/*"
				element={
					<Routes>
						<Route
							index
							element={
								<Suspense fallback={<PreloaderInside />}>
									<Notebooks />
								</Suspense>
							}
						/>
						<Route
							path="create"
							element={
								<Suspense fallback={<PreloaderInside />}>
									<SingleCreateNotebooks />
								</Suspense>
							}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				}
			></Route>

			<Route
				path=":categoryId/*"
				element={<RoutesCategoriesDefault holdings={holdings} />}
			/>

			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

function DashboardRouter({ holdings }) {
	const navigate = useNavigate()
	const { status } = useInfo()
	const { data: user, status: statusUser } = useUser()

	useEffect(() => {
		if (status === 'success') {
			if (!holdings?.children?.length) {
				navigate('agriculture/holdings/create', { replace: true })
			}
		}
	}, [status, holdings, navigate])

	if (status !== 'success') return null
	if (statusUser !== 'success') return null

	return <DashboardRouterInner holdings={holdings} user={user} />
}

export default DashboardRouter
